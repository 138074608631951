import { Box, Link as MuiLink } from '@mui/material';
import { ReactComponent as GoogleLogo } from '../assets/google.svg';
import "../styles.css";
import Google from '../Functions/Google';
export default function AuthGoogle(props) {
    //const location = useLocation();
    //let from = ((location.state)?.from?.pathname) || '/';
    const params = new URLSearchParams(document.location.search)

    const authGoogle = async () => {
        let parametros = {
            clientMac: params.get('clientMac'),
            apMac: params.get('apMac'),
            ssidName: params.get('ssidName'),
            radioId: params.get('radioId'),
            site: params.get('site'),
            redirectUrl: params.get('redirectUrl'),
            t: params.get('t')
        }
        let url = (await Google.getUlr(parametros)).data
        window.location.replace(url)
    }


    return (
        <div>
            <div className='form' style={{ marginTop: 0, paddingTop: 0 }}>
                <div style={{ marginBottom: "10px" }}>
                    Acesse com outros Provedores:
                </div>
                <Box
                    sx={{
                        backgroundColor: '#e5e7eb',
                        p: { xs: '1rem', sm: '1rem' },
                        borderRadius: 2,
                    }}
                >
                    <MuiLink
                        //href={urlGoogle}
                        onClick={authGoogle}
                        sx={{
                            backgroundColor: '#f5f6f7',
                            borderRadius: 1,
                            py: '0.6rem',
                            columnGap: '0.5rem',
                            textDecoration: 'none',
                            color: '#393e45',
                            cursor: 'pointer',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: '#fff',
                                boxShadow: '0 1px 13px 0 rgb(0 0 0 / 15%)',
                            },
                        }}
                        display='flex'
                        flexDirection={'column'}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <GoogleLogo style={{ height: '2rem', marginBottom: 5 }} />
                        <div style={{ fontSize: 20 }}>
                            Google
                        </div>
                        <div style={{ fontSize: 15 }}>
                            Conta Institucional (@ifsul.edu.br)
                        </div>
                    </MuiLink>
                </Box>

            </div>
        </div>
    );
};

