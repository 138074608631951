import * as React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Auth from "./Views/Auth"
import RetornoOauthGoogle from './Views/RetornoOauthGoogle';


export default function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route Component={Auth} path="/" exact />
                <Route Component={RetornoOauthGoogle} path="/oauth/google" />
            </Routes>
        </BrowserRouter>
    )
}