import ConfigIndex from '../Config'
import axios from 'axios';

export default class Google {
    static async getUlr(params) {
        let url = ConfigIndex.URL_BACKEND + '/google/url';
        let obj = { params }
        return await axios.post(
            url,
            obj
        )
    }

    static async authGoogle(code, params) {
        let url = ConfigIndex.URL_BACKEND + '/google/auth';
        let obj = { code, params }
        return await axios.post(
            url,
            obj
        )
    }
}