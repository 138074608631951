import ConfigIndex from '../Config'
import axios from 'axios';

export default class LDAP {
    static async authLDAP(user, password, params) {
        let url = ConfigIndex.URL_BACKEND + '/ldap/auth';
        let obj = { user, password, params }
        return await axios.post(
            url,
            obj
        )
    }
}