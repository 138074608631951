import * as React from 'react';
import "../styles.css";
import AuthLDAP from './AuthLDAP';
import AuthGoogle from './AuthGoogle';
import logoif from '../assets/PassoFundo.jpg';

export default function Auth() {
    return (
        <div className="App">
            <img src={logoif} alt="logo" style={{marginTop: 15}}/>
            <AuthLDAP>

            </AuthLDAP>
            <AuthGoogle>

            </AuthGoogle>
        </div>
    )
}