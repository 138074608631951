import * as React from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';


import "../styles.css";
import LDAP from '../Functions/LDAP';

export default function AuthLDAP(props) {

  const params = new URLSearchParams(document.location.search)

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const [openError401, setOpenError401] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)

    let user = event.target.user.value
    let password = event.target.password.value
    let parametros = {
      clientMac: params.get('clientMac'),
      apMac: params.get('apMac'),
      ssidName: params.get('ssidName'),
      radioId: params.get('radioId'),
      site: params.get('site'),
      redirectUrl: params.get('redirectUrl'),
      t: params.get('t')
    }

    let response = null
    try {
      response = await LDAP.authLDAP(user, password, parametros)
      if (response.status === 200) {
        console.log("Autenticado!!!");
        let urlRedirect = parametros.redirectUrl ? parametros?.redirectUrl : 'http://www.ifsul.edu.br'
        window.location.replace(urlRedirect)
      }
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 404)
        setOpenError401(true)
      console.log("Erro: ", error.response.status);
    } finally {
      setLoading(false)
    }
  }

  const handleCloseError401 = () => {
    setOpenError401(false);
  };

  return (
    <div>
      <form className='form' onSubmit={handleSubmit}>
        <TextField
          className='textField'
          id="user"
          label="Usuário"
          variant="standard" />
        <TextField
          className="textField"
          id="password"
          label="Senha"
          variant="standard"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }}
        />
        <LoadingButton type="submit" style={{ marginTop: "10px" }} variant="contained" loading={loading} >Acessar</LoadingButton>

      </form>
      <Dialog
        open={openError401}
        onClose={handleCloseError401}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Erro de Autenticação"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Usuário ou senha informado são inválidos!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError401}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}