import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import Google from '../Functions/Google'
import { useNavigate } from 'react-router-dom'

const params = new URLSearchParams(document.location.search)

export default function RetornoOauthGoogle() {
    const [openError401, setOpenError401] = React.useState(false);
    const [redirectError, setRedirectError] = useState("/")
    const navigate = useNavigate()

    const authGoogle = async (code, params) => {
        let response = null
        let urlRedirect = params.redirectUrl ? params?.redirectUrl : 'http://www.ifsul.edu.br'
        try {
            response = await Google.authGoogle(code, params)
            if (response.status === 200) {
                console.log("Autenticado!!!");
                window.location.replace(urlRedirect)
            }
        } catch (error) {
            if (error?.response?.status === 401 || error?.response?.status === 404) {
                setOpenError401(true)
                setRedirectError(urlRedirect)
            }
            console.log("Erro: ", error);
        } finally {
            
        }
    }

    const handleCloseError401 = () => {
        setOpenError401(false);
        navigate(redirectError)
    };

    useLayoutEffect(() => {
        authGoogle(params.get('code'), JSON.parse(params.get('state')));
    }, [])
    return (
        <div className="App">
            <div className='form'>
                <Typography
                    style={{ marginTop: 30 }}
                    fontSize={25}>
                    Processando sua requisição...
                    Aguarde!
                </Typography>
            </div>
            <Dialog
                open={openError401}
                onClose={handleCloseError401}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Erro de Autenticação"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Não foi possível acessar os seus dados da conta Google!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseError401}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
